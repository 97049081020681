import { BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS, fromBN } from 'gasp-sdk';
import { BN, BN_ZERO } from '@polkadot/util';
import { useMemo } from 'react';
import { Asset, useAssets, useMangataAsset } from '../../token';
import { use3rdPartyRewards } from '../3rdParty';
import { useNativeRewards } from '../native';
import { use3rdPartyTotalPendingSchedules } from '../3rdParty/use3rdPartyTotalPendingSchedules';

export const usePoolRewards = (lpId: string) => {
  const { totalPendingSchedules } = use3rdPartyTotalPendingSchedules();
  const {
    thirdPartyRewardsAmountQuery: { data: scheduledRewardsAmount },
  } = use3rdPartyRewards();

  const { rewardQueriesByLpId } = useNativeRewards();

  const { assetsMap } = useAssets();
  const { mangataAsset } = useMangataAsset();

  const nativeRewardsAmount = rewardQueriesByLpId[lpId]?.data;
  const poolPendingRewardsSchedules = totalPendingSchedules?.get(lpId);

  const claimableRewardsList = useMemo(() => {
    const rewards: Array<[Asset, string]> = [];

    if (nativeRewardsAmount?.gt(BN_ZERO) && mangataAsset) {
      rewards.push([
        mangataAsset,
        fromBN(nativeRewardsAmount, BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS),
      ]);
    }

    scheduledRewardsAmount?.get(lpId)?.forEach((_amount, id) => {
      const asset = assetsMap.get(id);
      const amount = new BN(_amount.toString());

      if (asset && amount.gt(BN_ZERO)) {
        rewards.push([asset, fromBN(amount, asset.decimals)]);
      }
    });

    return rewards;
  }, [assetsMap, lpId, mangataAsset, nativeRewardsAmount, scheduledRewardsAmount]);

  const claimableRewards = new Map<string, [Asset, string]>(
    claimableRewardsList.map(([asset, amount]) => [asset.id, [asset, amount]]),
  );

  return {
    poolPendingRewardsSchedules,
    claimableRewards,
  };
};
