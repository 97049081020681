import { ApiPromise } from '@polkadot/api';
import {
  ActivateLiquidityFor3rdPartyRewardsReserveSource,
  QueryOptional,
} from '../../../../services';
import { BN_ZERO } from '@polkadot/util';
import { ReserveSourceForTx } from '../../../balance';
import { buildBalanceFromPayload } from './activateLiqFor3rdPartyRewardsService';
import { BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS, fromBN } from 'gasp-sdk';
import { QueryFunctionContext } from '@tanstack/react-query';

type ActivateLiquidityFor3rdPartyRewardsFeeParams = Readonly<
  [
    id: QueryOptional<string>,
    liquidityTokenId: QueryOptional<string>,
    address: QueryOptional<string>,
    rewardTokenId: QueryOptional<string>,
  ]
>;

const createActivateLiqFor3rdPartyRewardsFeeTx = (
  address: string,
  api: ApiPromise,
  id: string,
  rewardTokenId: string,
  sources: ReserveSourceForTx<ActivateLiquidityFor3rdPartyRewardsReserveSource>,
) => {
  if (!sources.hasAvailable) {
    return;
  }

  if (sources.isBatch) {
    const txs = sources.available.map(([type, amount]) => {
      return api.tx.proofOfStake
        .activateLiquidityFor3rdpartyRewards(
          id,
          amount.toString(),
          rewardTokenId,
          buildBalanceFromPayload(type),
        )
        .paymentInfo(address);
    });

    return txs;
  }

  return [
    api.tx.proofOfStake
      .activateLiquidityFor3rdpartyRewards(
        id,
        sources.totalAvailableBalance,
        rewardTokenId,
        buildBalanceFromPayload(sources.firstAvailableSource),
      )
      .paymentInfo(address),
  ];
};

export const getActivateLiquidityFor3rdPartyRewardsFee =
  (
    api: ApiPromise | null,
    sources: ReserveSourceForTx<ActivateLiquidityFor3rdPartyRewardsReserveSource>,
  ) =>
  async ({
    queryKey: [, liquidityTokenId, address, rewardTokenId],
  }: QueryFunctionContext<ActivateLiquidityFor3rdPartyRewardsFeeParams>) => {
    if (!api || !address || !sources.hasAvailable || !liquidityTokenId || !rewardTokenId) {
      return null;
    }

    const txFees = await Promise.all(
      createActivateLiqFor3rdPartyRewardsFeeTx(
        address,
        api,
        liquidityTokenId,
        rewardTokenId,
        sources,
      ) || [],
    );

    const totalFee = txFees.reduce((acc, fee) => acc.add(fee.partialFee), BN_ZERO);

    return fromBN(totalFee, BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS);
  };
