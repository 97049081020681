import { QueryOptional } from '../../../../services';
import { useTokenContractQuery } from './query/useTokenContractQuery';

export const useTokenContract = (
  chainId: QueryOptional<string>,
  contract: QueryOptional<string>,
  userAddress: QueryOptional<string>,
) => {
  const { tokenContractMetadataQuery } = useTokenContractQuery(chainId, contract, userAddress);

  return {
    tokenContractMetadataQuery,
  };
};
