import { fromBN } from 'gasp-sdk';
import { uniq } from 'lodash-es';
import { UseQueryResult } from '@tanstack/react-query';
import { getPromotedPoolApr } from './services/PromotedPoolAprService';
import { PromotedPoolsRewards } from '../rewards/services/PromotedPoolRewards';
import { TransformPoolsResult } from '../../all/transformers/AllPoolsTransformers';
import {
  AssetRegistryAssetMetadata,
  PromotedPoolATvlResult,
  QueryOptional,
} from '../../../../services';
import { EnvConfig } from '../../../../envConfig';
import { usePriceDiscoveryQueries } from '../../../tokens';

interface UsePromotedPoolsAprParams {
  pools: QueryOptional<TransformPoolsResult>;
  assets: QueryOptional<AssetRegistryAssetMetadata[]>;
  promotedPoolATvlQueriesByLPId: Record<
    string,
    UseQueryResult<PromotedPoolATvlResult | null, unknown>
  >;
  poolRewardsByLPId: PromotedPoolsRewards;
}

export const usePromotedPoolsApr = ({
  pools,
  assets,
  promotedPoolATvlQueriesByLPId,
  poolRewardsByLPId,
}: UsePromotedPoolsAprParams) => {
  const mangataToken = assets?.find((a) => a.id === EnvConfig.TOKEN_ID);
  const promotedLPIds = Object.keys(poolRewardsByLPId);
  const tokenSymbolsToDiscover = uniq(
    promotedLPIds.reduce<string[]>((acc, liquidityTokenId) => {
      const pool = pools?.byLPId[liquidityTokenId];

      if (pool) {
        acc = [...acc, pool.firstAsset.symbol, pool.secondAsset.symbol];
      }
      return acc;
    }, []),
  );

  const { priceDiscoveryQueriesBySymbol } = usePriceDiscoveryQueries(tokenSymbolsToDiscover);

  const poolAprs = promotedLPIds.map((liquidityTokenId) => {
    const pool = pools?.byLPId[liquidityTokenId];

    return getPromotedPoolApr({
      pool,
      prices: priceDiscoveryQueriesBySymbol,
      poolATvl: promotedPoolATvlQueriesByLPId[liquidityTokenId]?.data,
      annualRewards: fromBN(
        poolRewardsByLPId[liquidityTokenId].annualMgxRewards,
        parseInt(mangataToken?.decimals ?? '0'),
      ),
      mgxSymbol: mangataToken?.symbol,
    });
  });

  const poolAprsByLPId = promotedLPIds.reduce<Record<string, string>>((acc, lpId, index) => {
    const poolApr = poolAprs[index];
    if (poolApr) {
      acc[lpId] = poolApr;
    }
    return acc;
  }, {});

  return { poolAprsByLPId, isLoading: poolAprs.some((apr) => apr === null) };
};
