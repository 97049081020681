import { BN } from '@polkadot/util';
import { Decimal } from 'decimal.js';
import { fromBN } from 'gasp-sdk';
import { StashChannel, StashToken, PaymentInfo } from '../../../../services';
import { EnvConfig } from '../../../../envConfig';
import { FeeInfo } from '../../';

interface TransformFeeData {
  channel: StashChannel | undefined;
  asset: StashToken | null;
  tokens: StashToken[] | undefined;
}

export const transformFee =
  ({ tokens, channel, asset }: TransformFeeData) =>
  (fee: PaymentInfo | null): FeeInfo | null => {
    if (!fee || !channel || !asset || !tokens) {
      return null;
    }

    const feeAsset = tokens?.find(
      (token) => token.symbol === fee.feeAsset && token.channelId === channel.channelId,
    );

    if (!feeAsset) {
      return null;
    }

    const destinationFeeAsset = tokens?.find(
      (token) =>
        token.symbol === asset.symbol && token.channelId === EnvConfig.MANGATA_PARACHAIN_ID,
    );

    if (!destinationFeeAsset) {
      return null;
    }

    const feePerSec = new Decimal(destinationFeeAsset.feePerSec);
    const mangataXcmTransferWeight = new Decimal(150000000 * 4);
    const exponent = new Decimal(`1e${destinationFeeAsset.decimals}`);

    const destinationFee = feePerSec.mul(mangataXcmTransferWeight).div(1000000000000).div(exponent);

    return {
      origin: {
        amount: fromBN(new BN(fee.fee), parseInt(feeAsset.decimals, 10)).toString(),
        asset: feeAsset,
      },
      destination: {
        amount: destinationFee.toString(),
        asset: destinationFeeAsset,
      },
      raw: {
        origin: {
          amount: fee.fee,
          asset: feeAsset,
        },
        destination: {
          amount: destinationFee.mul(`1e${destinationFeeAsset.decimals}`).toString(),
          asset: destinationFeeAsset,
        },
      },
    };
  };
