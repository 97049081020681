import { FormattedMessage } from 'react-intl';
import {
  Container,
  Text,
  AccountSelect,
  TokenInput,
  Button,
  WidgetDetailRow,
  ErrorBanner,
  AmountTooltip,
  Skeleton,
  IconButton,
} from 'ui';
import { ChainSelector } from '../ChainSelector/ChainSelector';
import { useDepositStore } from '../../store/useDepositStore';
import {
  RollupDepositValidationError,
  useRollupDepositValidation,
} from '../../deposit/useRollupDepositValidation';
import {
  Asset,
  QueryOptional,
  WalletAccount,
  XcmOperation,
  useMetamaskNetwork,
  useModalStore,
  useRollupChainsQuery,
  useUserStore,
} from 'core';
import { ReactComponent as CloseIcon } from 'icons/close.svg';
import { LockedWalletLayout } from '../../../Wallet/layouts/Locked/LockedWalletLayout';

interface DepositDefaultContentProps {
  onTokenSelectClick(): void;
  accounts: WalletAccount[] | undefined;
  isApproveInProgress: boolean;
  depositTokens: Asset[] | undefined;
  depositBalances: Map<string, string> | undefined;
  freeBalance: QueryOptional<string>;
  isDepositReady: boolean;
  isDepositing: boolean;
  submitDeposit(): void;
}
export const DepositDefaultContent = ({
  onTokenSelectClick,
  accounts,
  isApproveInProgress,
  depositTokens,
  depositBalances,
  freeBalance,
  isDepositReady,
  isDepositing,
  submitDeposit,
}: DepositDefaultContentProps) => {
  const { closeDeposit } = useModalStore();
  const { isWalletUnlocked } = useUserStore();
  const { rollupChainsQuery } = useRollupChainsQuery();
  const { error, getButtonText, getErrorText, feeAmount, isFeeError } =
    useRollupDepositValidation(isApproveInProgress);
  const {
    originAccount,
    computed: { hasAmount },
    setOriginAccount,
    chain,
    setChain,
    setAsset,
    asset,
    amount,
    setAmount,
  } = useDepositStore();
  const { isChainConnected, requestChain } = useMetamaskNetwork();
  const stashChain = rollupChainsQuery.data?.find((c) => c.chainId === chain?.id);
  const isMetamaskChainConnected = isChainConnected(chain?.id);

  const renderLockedWalletInfo = () => {
    return (
      <Container fullWidth className="relative">
        <LockedWalletLayout className="my-8" />
        <IconButton Icon={CloseIcon} onClick={closeDeposit} />
      </Container>
    );
  };

  const renderFee = (feeAmount?: string | null) => {
    if (feeAmount) {
      return (
        <Text
          type="label"
          color={
            error === RollupDepositValidationError.InsufficientNativeTokenBalance
              ? 'alert'
              : 'secondary'
          }
          className="mr-2"
          data-testid="fee-value"
        >
          <AmountTooltip
            id={feeAmount}
            value={feeAmount}
            options={{ precision: 3, nonZeroPrecision: true }}
            className="mr-1"
          />
          {stashChain?.nativeToken.symbol}
        </Text>
      );
    }

    if (isFeeError) {
      return '-';
    }

    return <Skeleton width="80px" height="10px" rounded />;
  };

  const handleDepositSubmit = () => {
    if (isMetamaskChainConnected) {
      submitDeposit();
    } else if (chain?.id) {
      requestChain(chain.id);
    }
  };

  return isWalletUnlocked ? (
    <>
      <Container fullWidth column>
        <Container alignItems="center" justifyContent="space-between" fullWidth>
          <Text id="bridge.deposit.title" type="title-1" />
          <IconButton data-testid="deposit-modal-close" Icon={CloseIcon} onClick={closeDeposit} />
        </Container>
        <Text id="bridge.deposit.desc" color="secondary" className="mt-4 w-3/4" />
      </Container>

      <Container className="mt-6" column fullWidth>
        <Text id="bridge.deposit.from" color="secondary" uppercase />

        <Container alignItems="center" justifyContent="stretch" fullWidth className="gap-2">
          {accounts && (
            <AccountSelect
              disabled
              accounts={accounts}
              selectedAccount={originAccount}
              onSelect={setOriginAccount}
              data-testid="account-select-from"
            />
          )}
          <ChainSelector
            chain={chain}
            setChain={setChain}
            operation={XcmOperation.Deposit}
            channels={rollupChainsQuery.data}
          />
        </Container>
      </Container>

      {chain && depositTokens && (
        <TokenInput
          labelId="bridge.input.token"
          isInvalid={error === RollupDepositValidationError.InsufficientTokenBalance}
          selectedToken={asset}
          onTokenSelect={setAsset}
          onAmountChange={setAmount}
          tokens={depositTokens}
          balances={depositBalances}
          freeBalance={freeBalance}
          amount={amount || ''}
          className="mt-6 mb-2 bg-base"
          isBorderless
          settingsVisible={false}
          onTokenSelectOverride={onTokenSelectClick}
        />
      )}

      {chain && asset && amount && hasAmount && originAccount && (
        <Container column fullWidth className="my-2">
          <WidgetDetailRow
            label={
              <Text
                id="bridge.details.gasFee"
                type="label"
                color={
                  error === RollupDepositValidationError.InsufficientNativeTokenBalance
                    ? 'alert'
                    : 'secondary'
                }
                className="mr-2"
              />
            }
            value={renderFee(feeAmount)}
            data-testid="origin-fee"
            className="mt-2"
          />
        </Container>
      )}

      {error !== null && (
        <ErrorBanner data-testid="deposit-error-message">{getErrorText()}</ErrorBanner>
      )}

      <Button
        isDisabled={!isDepositReady || error !== null || isDepositing || isApproveInProgress}
        variant="primary"
        size="l"
        fullWidth
        onClick={handleDepositSubmit}
        data-testid="submit-deposit-button"
        className="mt-6"
      >
        <FormattedMessage
          id={getButtonText(isMetamaskChainConnected) ?? ''}
          values={{ newChain: stashChain?.name }}
        />
      </Button>
    </>
  ) : (
    renderLockedWalletInfo()
  );
};
