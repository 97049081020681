import cls from 'classnames';
import { ClassName, TestId } from 'core';
import { Container } from '../Container/Container';
import { Text } from '../Text/Text';
import { useIntl } from 'react-intl';
import { ReactComponent as ChevronDownIcon } from '../../../assets/icons/chevron-down.svg';
import { useRef, useState } from 'react';
import { useClickOutside } from '../../hooks';

interface InputProps
  extends Pick<React.InputHTMLAttributes<HTMLInputElement>, 'type' | 'placeholder'>,
    ClassName,
    TestId {
  options?: Array<{ key: string; value: string | React.ReactNode }>;
  onChange?(value: string): void;
  value: string;
  renderValue?: (key: string) => React.ReactNode;
  label?: string;
  isValid?: boolean;
}

export const Input = ({
  'data-testid': testId,
  className,
  type = 'text',
  onChange,
  value,
  placeholder,
  options,
  label,
  isValid,
  renderValue,
}: InputProps) => {
  const intl = useIntl();

  const ref = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState(false);

  useClickOutside([ref.current], (isOut) => isOut && isOpen && setIsOpen(false));

  const handleOptionSelect = (key: string) => {
    setIsOpen(false);
    onChange?.(key);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(e.target.value);
  };

  const renderOptions = () => {
    return options?.map(({ key, value }) => (
      <Container key={key} fullWidth onClick={() => handleOptionSelect(key)}>
        {value}
      </Container>
    ));
  };

  const toggleIsOpen = () => setIsOpen(!isOpen);

  const INPUT_CLASS = cls(
    'w-full rounded-lg border px-3 py-2 placeholder:font-body-m font-body-m placeholder:text-secondary outline-none focus:ring-0',
    options && 'pr-8',
    isValid ? 'border-default' : 'border-alert',
    !isValid && 'hover:!border-alert focus-within:!border-alert',
  );

  return (
    <Container
      ref={ref}
      fullWidth
      column
      className={cls('relative', className)}
      data-testid={testId}
    >
      <Text color="secondary" type="body-s" uppercase id={label} className="text-[11px] mb-2" />
      <div className="relative w-full">
        {options && (
          <div
            onClick={toggleIsOpen}
            className={cls(INPUT_CLASS, 'cursor-default', !isValid && '[&>*]:text-alert')}
          >
            {renderValue ? renderValue(value) : value}
          </div>
        )}
        {!options && (
          <input
            data-testid={`${testId}-input`}
            type={type}
            onChange={handleChange}
            value={value}
            className={cls(INPUT_CLASS, !isValid && 'text-alert')}
            onClick={options && toggleIsOpen}
            placeholder={placeholder && intl.formatMessage({ id: placeholder })}
          />
        )}

        {options && (
          <div
            className="absolute right-2 top-1/2 -translate-y-1/2 cursor-pointer"
            onClick={toggleIsOpen}
          >
            <ChevronDownIcon className="fill-icon-secondary w-6 h-auto" />
          </div>
        )}
      </div>
      {options && isOpen && (
        <Container
          fullWidth
          column
          className="absolute top-full mt-2 z-50 bg-widget rounded-lg border-default border shadow-lg overflow-hidden"
        >
          {renderOptions()}
        </Container>
      )}
    </Container>
  );
};
