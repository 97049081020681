import { BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS, fromBN } from 'gasp-sdk';
import { QueryFunctionContext } from '@tanstack/react-query';
import { ApiPromise } from '@polkadot/api';
import { Decimal } from 'decimal.js';
import { QueryOptional } from '../../../../services';
import { BN, BN_ZERO } from '@polkadot/util';
import { get3rdPartyLiquidityDeactivationTxs } from '../../deactivateLiqFor3rdPartyRewards/services/deactivateLiqFor3rdPartyRewardsService';

type DeactivateLiquidityFeeParams = Readonly<
  [
    key: QueryOptional<string>,
    liqId: QueryOptional<string>,
    address: QueryOptional<string>,
    amount: QueryOptional<string>,
    active3rdPartyLiquidities: QueryOptional<[string, BN][]>,
  ]
>;

export const getDeactivateLiquidityFee =
  (api: ApiPromise | null) =>
  async ({
    queryKey: [, liqId, address, amount, active3rdPartyLiquidities],
  }: QueryFunctionContext<DeactivateLiquidityFeeParams>) => {
    if (!api || !address || !liqId || !amount || !active3rdPartyLiquidities) {
      return null;
    }

    const nativeDeactivationTx = api.tx.proofOfStake.deactivateLiquidityForNativeRewards(
      liqId,
      new Decimal(`${amount}e${BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS}`).toFixed(),
    );

    if (active3rdPartyLiquidities.length > 0) {
      const txs = [
        ...get3rdPartyLiquidityDeactivationTxs(api, liqId, active3rdPartyLiquidities),
        nativeDeactivationTx,
      ];

      const txsFeeInfo = await Promise.all(txs.map((tx) => tx.paymentInfo(address)));
      const batchingFeeInfo = await api.tx.utility.batchAll([]).paymentInfo(address);

      const totalFee = txsFeeInfo
        .reduce((acc, curr) => acc.add(curr.partialFee), BN_ZERO)
        .add(batchingFeeInfo.partialFee);

      return fromBN(totalFee, BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS);
    } else {
      const feeInfo = await nativeDeactivationTx.paymentInfo(address);

      return fromBN(feeInfo.partialFee, BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS);
    }
  };
