import { MangataGenericEvent } from 'gasp-sdk';
import { TxStatus } from './Transaction';
import { TxBase } from './TxBase';

export class SdkTx extends TxBase {
  private extrinsic?: () => Promise<MangataGenericEvent[] | null>;

  async send() {
    try {
      const res = await this.extrinsic?.();

      if (!this.options?.doneOnTrigger) {
        this.store.set(this.id, { status: TxStatus.Success });
      }

      this.options?.onDone?.();
      return res;
    } catch (e) {
      this.handleError(e);
    } finally {
      if (!this.options?.doneOnTrigger) {
        this.scheduleHide();
      }
    }
  }

  setTx(call: () => Promise<MangataGenericEvent[] | null>) {
    this.extrinsic = call;

    return this;
  }

  setExplorerUrl(url: string) {
    this.explorerUrl = url;
    this.store.set(this.id, { explorerUrl: url });
    return this;
  }
}
