import { Decimal } from 'decimal.js';
import {
  useInternalBalance,
  useRollupWithdrawalFeeQuery,
  useRollupTokensQuery,
  isAmountGtZero,
} from 'core';
import { useWithdrawalStore } from '../store/useWithdrawalStore';
import { useIntl } from 'react-intl';
import { isAddress } from 'web3-validator';

export enum WithdrawalValidationError {
  MinAmount,
  InsufficientBalance,
  InsufficientBalanceForOriginFee,
  WrongAddress,
}

export const useRollupWithdrawalValidation = () => {
  const intl = useIntl();
  const { amount, asset, destinationAddress, chain } = useWithdrawalStore();
  const { rollupTokensQuery } = useRollupTokensQuery();
  const { internalBalancesQuery } = useInternalBalance();
  const { getFreeBalance } = useInternalBalance();

  const rollupTokens = rollupTokensQuery.data;
  const selectedRollupToken = rollupTokens?.find((token) => token.l2Id === asset?.id);
  const assetBalance = asset ? internalBalancesQuery.data?.get(asset.id) : null;
  const assetFreeBalance = asset
    ? new Decimal(`${assetBalance?.free.toString() || 0}e-${asset.decimals}`)
    : null;

  const { rollupWithdrawalFeeQuery } = useRollupWithdrawalFeeQuery(
    chain?.id,
    selectedRollupToken?.address,
    amount,
  );

  const fee = rollupWithdrawalFeeQuery.data;
  const currentFee = fee?.current;

  const error = (() => {
    const newError: Record<string, boolean> = {};

    if (assetFreeBalance?.lt(amount || 0)) {
      newError[WithdrawalValidationError.InsufficientBalance] = true;
    }

    if (destinationAddress && !isAddress(destinationAddress)) {
      newError[WithdrawalValidationError.WrongAddress] = true;
    }

    if (!currentFee || !asset) return newError;

    if (new Decimal(currentFee.amount).gt(getFreeBalance(currentFee) || 0)) {
      newError[WithdrawalValidationError.InsufficientBalanceForOriginFee] = true;
    }

    if (asset.id === currentFee.id && assetFreeBalance?.minus(currentFee.amount).lt(amount || 0)) {
      newError[WithdrawalValidationError.InsufficientBalanceForOriginFee] = true;
    }

    return newError;
  })();

  const getErrorText = () => {
    switch (true) {
      case error[WithdrawalValidationError.InsufficientBalance]:
        return intl.formatMessage(
          { id: 'bridge.error.insufficientTokenBalance' },
          { symbol: asset?.symbol },
        );
      case error[WithdrawalValidationError.InsufficientBalanceForOriginFee]:
        return intl.formatMessage(
          { id: 'bridge.error.insufficientBalanceForOriginFee' },
          { symbol: currentFee?.symbol },
        );
      case error[WithdrawalValidationError.WrongAddress]:
        return intl.formatMessage({ id: 'bridge.error.wrongAddress' });
    }
  };

  const getButtonText = (isChainSwitchRequired: boolean) => {
    switch (true) {
      case chain === null:
        return 'bridge.button.selectChain';
      case amount === null || !isAmountGtZero(amount):
        return 'bridge.button.amount';
      case !destinationAddress:
        return 'bridge.button.selectAccount';
      case isChainSwitchRequired:
        return 'common.switch.defaultNetwork';
      default:
        return 'bridge.button.withdraw';
    }
  };

  return {
    fee,
    error,
    getButtonText,
    getErrorText,
  };
};
