import {
  Button,
  Container,
  AmountTooltip,
  Skeleton,
  Text,
  TokenInput,
  WidgetDetailRow,
  ErrorBanner,
  IconButton,
  ETHFeeInfo,
} from 'ui';
import { ChainSelector } from '../components/ChainSelector/ChainSelector';
import { useWithdrawalStore } from '../store/useWithdrawalStore';
import {
  Asset,
  FeeAssetAmount,
  TxAsset,
  TxType,
  useAccounts,
  useInternalBalance,
  useMetamaskNetwork,
  useModalStore,
  useRollupChainsQuery,
  useSafeTransaction,
  WalletAccount,
  XcmOperation,
} from 'core';
import { ReactComponent as CloseIcon } from 'icons/close.svg';
import {
  useRollupWithdrawalValidation,
  WithdrawalValidationError,
} from './useRollupWithdrawalValidation';
import { isEmpty } from 'lodash-es';
import { AddressInput } from '../components/AddressInput/AddressInput';
import { isAddress } from 'web3-validator';

interface RollupWithdrawalFormProps {
  onTokenSelectClick(): void;
  accounts: WalletAccount[] | undefined;
  assets: Asset[] | undefined;
  balances: Map<string, string> | undefined;
  isWithdrawalReady: boolean;
  isWithdrawing: boolean;
  submitWithdrawal(): void;
}

export function RollupWithdrawalForm({
  submitWithdrawal,
  onTokenSelectClick,
  assets,
  balances,
  isWithdrawalReady,
  isWithdrawing,
}: RollupWithdrawalFormProps) {
  const { error, fee, getErrorText, getButtonText } = useRollupWithdrawalValidation();
  const { getFreeBalance } = useInternalBalance();
  const { rollupChainsQuery } = useRollupChainsQuery();
  const { selectedAccount } = useAccounts();
  const { isChainSwitchRequired } = useMetamaskNetwork();
  const { destinationAddress } = useWithdrawalStore();
  const { closeWithdrawal } = useModalStore();

  const { asset, setAsset, amount, setAmount, chain, setChain, setDestinationAddress } =
    useWithdrawalStore();

  const { submitTxWithChecks } = useSafeTransaction(
    {
      spendingAssets: asset && amount ? [{ ...asset, amount }] : [],
      adaptiveFee: fee,
      type: TxType.RollupWithdrawal,
    },
    submitWithdrawal,
  );

  const isDestinationAddressValid = isAddress(destinationAddress ?? '');

  const renderFee = (fee?: FeeAssetAmount | TxAsset | null) => {
    if (fee) {
      return (
        <Text
          type="label"
          color={
            error[WithdrawalValidationError.InsufficientBalanceForOriginFee] ? 'alert' : 'secondary'
          }
          className="mr-2"
          data-testid="fee-value"
        >
          <AmountTooltip
            id={fee.amount}
            value={fee.amount}
            options={{ precision: 3, maxChars: 15, nonZeroPrecision: true }}
            className="mr-1"
          />
          {'asset' in fee ? fee.asset.symbol : fee.symbol}
        </Text>
      );
    }

    return <Skeleton width="80px" height="10px" rounded />;
  };

  return (
    <Container fullWidth column>
      <Container fullWidth column>
        <Container alignItems="center" justifyContent="space-between" fullWidth>
          <Container alignItems="center">
            <Text id="bridge.withdrawal.title" type="title-1" className="whitespace-nowrap mr-4" />
            <ChainSelector
              chain={chain}
              setChain={setChain}
              operation={XcmOperation.Withdrawal}
              channels={rollupChainsQuery.data}
            />
          </Container>
          <IconButton Icon={CloseIcon} onClick={closeWithdrawal} />
        </Container>
      </Container>

      {chain && assets && (
        <TokenInput
          labelId="bridge.input.token"
          isInvalid={error[WithdrawalValidationError.InsufficientBalance]}
          selectedToken={asset}
          onTokenSelect={setAsset}
          onAmountChange={setAmount}
          tokens={assets}
          balances={balances}
          amount={amount || ''}
          freeBalance={getFreeBalance(asset)}
          className="mt-6 bg-base"
          isBorderless
          settingsVisible={false}
          onTokenSelectOverride={onTokenSelectClick}
        />
      )}

      {destinationAddress && (
        <Container column className="mt-6" fullWidth>
          <Text id="bridge.withdraw.recipient" uppercase color="secondary" />

          <AddressInput
            onChange={setDestinationAddress}
            address={destinationAddress}
            selectedAccount={selectedAccount?.address ?? ''}
            className="mt-3"
          />
        </Container>
      )}

      {destinationAddress &&
        destinationAddress !== selectedAccount?.address &&
        isDestinationAddressValid && (
          <Container className="w-full rounded-xl bg-warning/[.2] p-4 mt-6">
            <Text
              id="bridge.withdrawal.recipientChange.warning.desc"
              values={{
                title: <Text weight="bold" id="bridge.withdrawal.recipientChange.warning.title" />,
              }}
            />
          </Container>
        )}

      {!isEmpty(error) && (
        <ErrorBanner className="mb-2 mt-6" data-testid="withdrawal-error-message">
          {getErrorText()}
        </ErrorBanner>
      )}

      {isWithdrawalReady && (
        <WidgetDetailRow
          className="mb-2"
          label={
            <Text
              color={
                error[WithdrawalValidationError.InsufficientBalanceForOriginFee]
                  ? 'alert'
                  : 'secondary'
              }
              id="bridge.details.originFee"
              type="label"
              className="mr-2"
            />
          }
          value={renderFee(fee?.current)}
          data-testid="destination-fee"
        />
      )}
      {fee?.current?.id === '1' && <ETHFeeInfo className="mt-2" />}

      <Button
        isDisabled={!isEmpty(error) || !isWithdrawalReady || isWithdrawing}
        variant="primary"
        size="l"
        fullWidth
        onClick={submitTxWithChecks}
        data-testid="submit-withdrawal-button"
        className="mt-6"
      >
        <Text id={getButtonText(isChainSwitchRequired)} type="body-l" />
      </Button>
    </Container>
  );
}
