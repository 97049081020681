import cls from 'classnames';
import { Asset, AssetType, usePreviousValue } from 'core';
import { get, isNil, isNull } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { EnvConfig } from '../../environment/EnvConfig';

import { ClassName, TestId } from '../../types';
import { Container } from '../Container/Container';
import { isIconAvailable } from './isIconAvailable';
import { AssetWithMetadata, SIZES_MAP, Icon, getIconOverlapSize } from './Icon';

export type TokenIconSize = 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl';
export type TokenIconType = 'row' | 'diagonal';

export interface TokenIconProps extends ClassName, TestId {
  token: Asset | Asset[] | string[] | string | null;
  size?: TokenIconSize;
  iconClassName?: string;
  type?: TokenIconType;
  isCompact?: boolean;
}

const DEFAULT_ICON = `${EnvConfig.ASSETS_URL}/tokens/default.png`;

export function TokenIcon({
  token,
  className,
  isCompact = true,
  size = 's',
  iconClassName,
  type = 'row',
  'data-testid': testId = 'token-icon',
}: TokenIconProps) {
  const [metadata, setMetadata] = useState<AssetWithMetadata[] | null>(null);
  const tokens = useMemo(() => {
    if (isNil(token)) return null;
    if (Array.isArray(token)) return token;
    if (typeof token === 'string') return token.split('-');
    return [token];
  }, [token]);
  const prevToken = usePreviousValue(token);

  const isTokenPropValid = useMemo(() => !isNil(token), [token]);

  useEffect(() => {
    if (token !== prevToken && isTokenPropValid) {
      fetchMetadata(tokens).then(setMetadata);
    }
  }, [isTokenPropValid, prevToken, token, tokens]);

  useEffect(() => {
    if (!isNull(metadata) || !isTokenPropValid) {
      return;
    }

    fetchMetadata(tokens).then(setMetadata);
  }, [isTokenPropValid, metadata, token, tokens]);

  const isPair = isPairToken(tokens);

  const getContainerWidth = () => {
    if (!isPair || (isPair && isCompact)) {
      return SIZES_MAP[size].width;
    }

    return SIZES_MAP[size].width * 2 - getIconOverlapSize(size, isCompact);
  };

  return (
    <Container
      inline
      data-testid={testId}
      alignItems="center"
      className={cls(className)}
      style={{ maxWidth: getContainerWidth() }}
    >
      {metadata &&
        metadata.map((asset, i) => (
          <Icon
            key={`${asset.id}-${asset.symbol}-${i}`}
            i={i}
            size={size}
            data-testid={testId}
            iconClassName={iconClassName}
            asset={asset}
            isCompact={isCompact}
            isPair={isPair}
            type={type}
          />
        ))}
    </Container>
  );
}

const getIconMetadata = async (asset: Partial<Asset> & Pick<Asset, 'symbol'>) => {
  const url = `${EnvConfig.ASSETS_URL}/tokens/${asset.symbol.toLowerCase()}.png`;
  return { ...asset, url, iconAvailable: await isIconAvailable(url) };
};

const fetchMetadata = async (tokens: string[] | Asset[] | null) => {
  if (!tokens) {
    return [];
  }

  const metadataPromise = Promise.all(
    tokens
      .map((asset) => {
        if (typeof asset === 'object' && asset.type === AssetType.LP) {
          const [firstSymbol, secondSymbol] = asset.symbol.split('-');

          return [
            getIconMetadata({ ...asset, symbol: firstSymbol }),
            getIconMetadata({ ...asset, symbol: secondSymbol }),
          ];
        }

        return getIconMetadata(typeof asset === 'string' ? { symbol: asset } : asset);
      })
      .flat(),
  );

  const metadata = (await metadataPromise).map((url) => ({
    ...url,
    url: url.iconAvailable ? url.url : DEFAULT_ICON,
  }));

  return metadata;
};

const isPairToken = (token: Asset[] | string[] | null) => {
  if (Array.isArray(token) && token.length === 2) {
    return true;
  }

  if ((get(token, '[0].type') || get(token, 'type')) === AssetType.LP) {
    return true;
  }

  return false;
};
