import { QueryOptional, QueryServiceReturnType } from '../../../../../services';
import { AssetOrigin, RollupToken } from '../../../..';
import { getTokenContractMetadata } from '../services/tokenContractService';
import Decimal from 'decimal.js';

export const transformTokenContractMetadata =
  (
    chainKey: string | undefined,
    chainId: QueryOptional<string>,
    tokens: QueryOptional<RollupToken[]>,
  ) =>
  (data: QueryServiceReturnType<typeof getTokenContractMetadata>): [RollupToken, string] | null => {
    const origin =
      chainKey &&
      {
        Ethereum: AssetOrigin.Ethereum,
        Arbitrum: AssetOrigin.Arbitrum,
      }[chainKey];

    if (
      !data ||
      !data.name ||
      !data.symbol ||
      !data.decimals ||
      !data.contract ||
      !data.address ||
      !chainId ||
      !origin
    ) {
      return null;
    }

    const balance = new Decimal(data.balance?.toString() ?? '0')
      .div(`1e${data.decimals.toString()}`)
      .toFixed();

    const l2Id = tokens?.find((t) => t.address === data.address)?.l2Id ?? null;

    return [
      {
        address: data.address,
        name: data.name.toString(),
        symbol: data.symbol.toString(),
        decimals: data.decimals.toString(),
        isNative: false,
        contract: data.contract,
        l2Id,
        origin,
        chainId,
      },
      balance,
    ];
  };
