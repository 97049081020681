import { UseQueryOptions, useQueries } from '@tanstack/react-query';
import { TokenId } from 'gasp-sdk';
import { zipObject } from 'lodash-es';
import { PromotedPoolATvlResult, QueryOptional, useApi } from '../../../../../services';
import { PromotedPoolATvlQueryKey, getPromotedPoolATvl } from '../services/PromotedPoolATvlService';
import { TransformPoolsResult } from '../../../all/transformers/AllPoolsTransformers';

const queryOptions = {
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  staleTime: 1000 * 60 * 5,
};

type PromotedPoolATvlQueryOptions = UseQueryOptions<
  PromotedPoolATvlResult | null,
  unknown,
  PromotedPoolATvlResult | null,
  PromotedPoolATvlQueryKey
>;

interface UsePromotedPoolATvlQueriesParams {
  liquidityTokenIds: QueryOptional<TokenId[]>;
  pools: QueryOptional<TransformPoolsResult>;
}

/**
 * Gets activated total value locked (ATVL) for each provided liquidity token ID.
 * ATVL is available only for promoted pools.
 */
export const usePromotedPoolATvlQueries = ({
  liquidityTokenIds,
  pools,
}: UsePromotedPoolATvlQueriesParams) => {
  const api = useApi();

  const queries: PromotedPoolATvlQueryOptions[] | null = liquidityTokenIds
    ? liquidityTokenIds.map((liquidityTokenId) => {
        const pool = pools?.byLPId[liquidityTokenId];

        return {
          queryKey: ['pool-tvl', pool?.liquidityTokenId, pool?.firstTokenId, pool?.secondTokenId],
          queryFn: getPromotedPoolATvl(api),
          enabled: !!pools && !!api,
          ...queryOptions,
        };
      })
    : null;

  const promotedPoolATvlQueries = useQueries({ queries: queries || [] });

  if (!liquidityTokenIds) {
    return {
      promotedPoolATvlQueriesByLPId: {},
    };
  }

  return {
    promotedPoolATvlQueriesByLPId: zipObject(liquidityTokenIds, promotedPoolATvlQueries),
  };
};
