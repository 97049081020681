import { EnvConfig, StashChannel, XcmOperation, QueryOptional, RollupStashChain } from 'core';
import { useIntl } from 'react-intl';
import { ChainSelect, ChainSelectItem, Container, Text } from 'ui';

interface ChainSelectorProps {
  chain: ChainSelectItem | null;
  setChain: (chain: ChainSelectItem | null) => void;
  operation: XcmOperation;
  channels: QueryOptional<StashChannel[] | RollupStashChain[]>;
}

export const getChainIconUrl = (chainId: string) =>
  `${EnvConfig.ASSETS_URL}/channels/${chainId}.png`;

export function ChainSelector({ chain, setChain, operation, channels }: ChainSelectorProps) {
  const intl = useIntl();

  const channelItems = channels?.map((channel) => {
    const chainId = 'channelId' in channel ? channel.channelId : channel.chainId;

    return {
      id: chainId,
      title: channel.name,
      icon: getChainIconUrl(chainId),
    };
  });

  const renderChainSelectorContent = () => {
    if (chain == null) {
      return <Text id="chainSelector.label" className="font-medium" />;
    }

    return (
      <Container alignItems="center" justifyContent="center">
        <img src={chain.icon} alt={chain.title} className="w-[24px] h-auto mr-2 rounded-full" />
        <Text type="title-4" color="primary">
          {chain.title}
        </Text>
      </Container>
    );
  };

  return channelItems ? (
    <Container column fullWidth alignItems="start">
      <Container alignItems="center" data-testid="chain-selector" fullWidth>
        <ChainSelect
          key="chain-select"
          selectedItem={chain}
          dropdownTitle={
            operation === XcmOperation.Withdrawal
              ? intl.formatMessage({ id: 'chainSelector.destinationChain' })
              : intl.formatMessage({ id: 'chainSelector.originChain' })
          }
          data={channelItems}
          onSelect={setChain}
          triggerContent={renderChainSelectorContent()}
        />
      </Container>
    </Container>
  ) : null;
}
