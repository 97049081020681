import {
  Asset,
  TxType,
  WalletAccount,
  isAmountGtZero,
  useAccounts,
  useAssets,
  useInternalBalance,
  useMetamaskNetwork,
  useModalStore,
  useRollupChainsQuery,
  useRollupTokensQuery,
  useRollupWithdrawalMutation,
  useTransaction,
  useTransactionStore,
  useWallet,
} from 'core';
import { useEffect, useMemo } from 'react';
import { WithdrawalUIState, useWithdrawalStore } from '../store/useWithdrawalStore';
import { getChainIconUrl } from '../components/ChainSelector/ChainSelector';
import { isNil } from 'lodash-es';

export const useRollupWithdrawalState = () => {
  const { remove } = useTransactionStore();
  const { isWithdrawalOpen, closeWithdrawal } = useModalStore();
  const {
    amount,
    asset,
    dispose,
    destinationAddress,
    setDestinationAddress,
    chain,
    setChain,
    setUIState,
  } = useWithdrawalStore();
  const { assetsMap } = useAssets();
  const { getFreeBalance } = useInternalBalance();
  const { rollupTokensQuery } = useRollupTokensQuery();
  const { rollupChainsQuery } = useRollupChainsQuery();
  const { defaultChain } = useMetamaskNetwork();
  const {
    rollupWithdrawalMutation: { data, mutate, isLoading: isWithdrawing },
  } = useRollupWithdrawalMutation();

  const rollupTokens = rollupTokensQuery.data;
  const selectedRollupToken = rollupTokens?.find((token) => token.l2Id === asset?.id);

  const {
    selectedAccount,
    accountsQuery: { data: accounts },
  } = useAccounts();

  const withdrawalAccounts = accounts?.filter((account) => !!account.address);
  const isWithdrawalSuccessful = !!data;

  const {
    selectedWalletQuery: { data: selectedWallet },
  } = useWallet();

  const { getLatestTxByType } = useTransaction();

  const tx = getLatestTxByType(TxType.RollupWithdrawal);

  const handleAccountSelect = (account: WalletAccount) => {
    setDestinationAddress(account.address);
  };

  useEffect(() => {
    if (defaultChain && isNil(chain?.id)) {
      setChain(
        {
          id: defaultChain.chainId,
          title: defaultChain.name,
          icon: getChainIconUrl(defaultChain.chainId),
        },
        false,
      );
    }
  }, [defaultChain, chain?.id, setChain]);

  useEffect(() => {
    if (isWithdrawalSuccessful) {
      setUIState(WithdrawalUIState.Success);
    }
  }, [isWithdrawalSuccessful, setUIState]);

  useEffect(() => {
    if (!destinationAddress) {
      setDestinationAddress(selectedAccount?.address ?? null);
      return;
    }
  }, [selectedAccount, setDestinationAddress, destinationAddress]);

  const stashChain = rollupChainsQuery.data?.find((c) => c.chainId === chain?.id);
  const withdrawalAssets = rollupTokens
    ?.map((token) => token.l2Id && token?.chainId === chain?.id && assetsMap?.get(token.l2Id))
    .filter(($): $ is Asset => !!$);

  const withdrawalBalances = new Map<string, string>(
    withdrawalAssets?.reduce((acc: [string, string][], asset) => {
      const freeBalance = getFreeBalance(asset);

      if (!asset) return acc;

      return [...acc, [asset.id, freeBalance ?? '0']];
    }, []),
  );

  const handleClose = () => {
    dispose();
    closeWithdrawal();
    remove(tx?.id);
  };

  const isWithdrawalReady = useMemo(
    () =>
      !!(
        isAmountGtZero(amount) &&
        asset &&
        destinationAddress &&
        selectedAccount &&
        selectedWallet
      ),
    [amount, asset, destinationAddress, selectedAccount, selectedWallet],
  );

  const submitWithdrawal = () => {
    if (
      !destinationAddress ||
      !selectedAccount ||
      !amount ||
      !selectedRollupToken ||
      !selectedWallet
    ) {
      return;
    }

    setUIState(WithdrawalUIState.Withdrawing);
    mutate({
      destinationAddress: destinationAddress,
      destinationChain: stashChain,
      userAddress: selectedAccount.address,
      tokenAddress: selectedRollupToken?.address,
      asset,
      amount,
    });
  };

  return {
    isWithdrawalOpen,
    isWithdrawalReady,
    submitWithdrawal,
    selectedAccount,
    selectedWallet,
    withdrawalAccounts,
    withdrawalAssets,
    withdrawalBalances,
    isWithdrawing,
    handleClose,
    handleAccountSelect,
    tx,
  };
};
