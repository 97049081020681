import { useQuery } from '@tanstack/react-query';
import { fetchRollupTokens } from '../services/rollupTokensService';
import { EnvConfig } from '../../../../../envConfig';
import { useWeb3, DEFAULT_QUERY_OPTIONS } from '../../../../../services';
import { useAssetMetadataQuery } from '../../../../token';
import { useRollupChainsQuery } from '../../../stash/query/useRollupChainsQuery';
import { useRollupTokenAddresMapsQuery } from '../../address/query/useRollupTokenAddressesQuery';

export const useRollupTokensQuery = () => {
  const web3s = useWeb3();
  const {
    rollupTokenAddressMapsQuery: { data: rollupTokenAddressMaps },
  } = useRollupTokenAddresMapsQuery();
  const { rollupChainsQuery } = useRollupChainsQuery();
  const {
    assetMetadataQuery: { data: metadata },
  } = useAssetMetadataQuery();
  const chains = rollupChainsQuery.data;

  const rollupTokensQuery = useQuery(
    ['rollup-tokens'],
    fetchRollupTokens(web3s, rollupTokenAddressMaps, chains, metadata),
    {
      ...DEFAULT_QUERY_OPTIONS,
      enabled:
        !!rollupTokenAddressMaps && !!web3s && !!chains && !!metadata && EnvConfig.isRollupEnv,
    },
  );

  return {
    rollupTokensQuery,
  };
};
