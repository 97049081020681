import { BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS, BN_ZERO, fromBN } from 'gasp-sdk';
import { QueryFunctionContext } from '@tanstack/react-query';
import { ApiPromise } from '@polkadot/api';
import { ActivateLiquidityReserveSource, QueryOptional } from '../../../../services';
import { ReserveSourceForTx } from '../../../balance';

type ActivateLiquidityFeeParams = Readonly<
  [
    id: QueryOptional<string>,
    address: QueryOptional<string>,
    amount: QueryOptional<string>,
    symbol: QueryOptional<string>,
  ]
>;

export const getActivateLiquidityFee =
  (api: ApiPromise | null, sources: ReserveSourceForTx<ActivateLiquidityReserveSource>) =>
  async ({
    queryKey: [, id, address, amount],
  }: QueryFunctionContext<ActivateLiquidityFeeParams>) => {
    if (!api || !address || !id || !amount || !sources.hasAvailable) {
      return null;
    }

    const txFees = await Promise.all(
      sources.available.map(([type, amount]) => {
        return api.tx.proofOfStake
          .activateLiquidityForNativeRewards(id, amount.toString(), type)
          .paymentInfo(address);
      }),
    );

    const totalFee = txFees.reduce((acc, fee) => acc.add(fee.partialFee), BN_ZERO);

    return fromBN(totalFee, BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS);
  };
