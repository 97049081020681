import Decimal from 'decimal.js';
import { BN, BN_ZERO } from '@polkadot/util';
import { QueryOptional } from '../../../services';
import { fromBN, toBN } from 'gasp-sdk';
import { routeExactIn } from '../../swap/private/routing/SwapRoutingService';
import { PoolWithRatio, PoolWithShare, useAllPoolsQuery } from '../../pool';
import { Asset, useAssets, useMangataAsset } from '../../token';
import { usePromotedPoolATvlQueries } from '../../pool/promoted/atvl/query/usePromotedPoolATvlQuery';
import { TransformPoolsResult } from '../../pool/all/transformers/AllPoolsTransformers';
import { use3rdPartyTokensQuery } from './query/use3rdPartyTokensQuery';
import { use3rdPartyTotalPendingSchedules } from './use3rdPartyTotalPendingSchedules';

const get3rdPartyRewardsApr = (
  pool: QueryOptional<PoolWithShare | PoolWithRatio>,
  rewardAsset: QueryOptional<Asset>,
  pendingSchedules: QueryOptional<BN>,
  allPoolsData: QueryOptional<TransformPoolsResult>,
  mangataAsset: Asset | null,
  poolATVL: QueryOptional<[BN, BN]>,
) => {
  if (!allPoolsData || !rewardAsset || !mangataAsset) {
    return null;
  }

  const poolATVLMgxSide = pool?.firstAsset?.id === mangataAsset.id ? poolATVL?.[0] : poolATVL?.[1];
  const poolATVLMgxSum = poolATVLMgxSide?.muln(2);

  const { bestAmount: rewardAssetToMgxRate } = routeExactIn(
    allPoolsData,
    rewardAsset,
    mangataAsset,
    toBN('1', rewardAsset.decimals) || BN_ZERO,
    true,
  );

  const totalPendingRewardsInMgx =
    pendingSchedules && rewardAssetToMgxRate
      ? new Decimal(pendingSchedules.toString()).mul(
          fromBN(rewardAssetToMgxRate, rewardAsset.decimals)?.toString(),
        )
      : null;

  const apr =
    totalPendingRewardsInMgx && poolATVLMgxSum?.gtn(0)
      ? totalPendingRewardsInMgx.div(poolATVLMgxSum?.toString() || 1).mul(100)
      : null;

  return apr?.gt(0) ? apr?.toFixed(2) : null;
};

export const use3rdPartyRewardsPoolApr = (pool: QueryOptional<PoolWithShare | PoolWithRatio>) => {
  const { thirdPartyTokensQuery } = use3rdPartyTokensQuery();
  const { allPoolsQuery } = useAllPoolsQuery();
  const { mangataAsset } = useMangataAsset();
  const { assetsMap } = useAssets();
  const { totalPendingSchedules } = use3rdPartyTotalPendingSchedules();

  const liquidityTokenId = pool?.liquidityTokenId;

  const { promotedPoolATvlQueriesByLPId } = usePromotedPoolATvlQueries({
    liquidityTokenIds: liquidityTokenId ? [liquidityTokenId] : null,
    pools: allPoolsQuery.data,
  });

  if (!liquidityTokenId) {
    return {
      aprMap: null,
      aprSum: null,
    };
  }

  const rewardTokenIds = thirdPartyTokensQuery?.data?.get(liquidityTokenId);

  const rewardTokens = rewardTokenIds
    ?.map((id) => assetsMap.get(id))
    .filter((asset): asset is Asset => !!asset);
  const poolATVL = promotedPoolATvlQueriesByLPId[liquidityTokenId || '']?.data;

  const aprList: [string, string | null][] | undefined = rewardTokens?.map((rewardAsset: Asset) => [
    rewardAsset.id,
    get3rdPartyRewardsApr(
      pool,
      rewardAsset,
      totalPendingSchedules?.get(liquidityTokenId)?.get(rewardAsset.id),
      allPoolsQuery.data,
      mangataAsset,
      poolATVL,
    ),
  ]);

  const aprSum = aprList?.reduce(
    (acc, [, apr]) => (apr ? acc.add(new Decimal(apr)) : acc),
    new Decimal(0),
  );

  return {
    aprMap: aprList ? new Map(aprList) : null,
    aprSum: aprSum?.gt(0) ? aprSum?.toFixed(2) : null,
  };
};
