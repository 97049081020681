import { useSpring, useTransition } from '@react-spring/web';
import { RefObject, useEffect, useState } from 'react';
import { WithdrawalUIState, useWithdrawalStore } from '../store/useWithdrawalStore';
import { Transaction, TxStatus } from 'core';

export const useRollupWithdrawalTransitions = (
  containerRef: RefObject<HTMLDivElement>,
  tx: Transaction | null,
) => {
  const [containerHeight, setContainerHeight] = useState(360);

  const { uiState } = useWithdrawalStore();
  const ref = containerRef.current;

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setContainerHeight(entry.target.clientHeight);
      }
    });
    if (ref) {
      resizeObserver.observe(ref);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [ref, uiState]);

  const containerSpring = useSpring({
    minHeight: containerHeight,
  });

  const selectTokenTransition = useTransition(uiState === WithdrawalUIState.SelectToken, {
    from: { x: '100%', opacity: 0 },
    enter: { x: '0%', opacity: 1, height: containerHeight },
    leave: { x: '100%', opacity: 0 },
    duration: 150,
  });

  const txInfoTransition = useTransition(uiState === WithdrawalUIState.Withdrawing, {
    from: { x: '100%', opacity: 0 },
    enter: { x: '0%', opacity: 1, height: containerHeight },
    leave: {
      x: tx?.status === TxStatus.Success ? '-100%' : '100%',
      opacity: 0,
    },
    duration: 150,
  });

  const successTransition = useTransition(uiState === WithdrawalUIState.Success, {
    from: { x: '100%', opacity: 0 },
    enter: { x: '0%', opacity: 1, height: containerHeight },
    leave: { x: '100%', opacity: 0 },
    duration: 150,
  });

  const defaultStateTransition = useTransition(uiState === WithdrawalUIState.Default, {
    from: { x: '-100%', opacity: 0 },
    enter: { x: '0%', opacity: 1, height: containerHeight },
    leave: { x: '-100%', opacity: 0 },
    initial: null,
    duration: 150,
  });

  return {
    containerSpring,
    selectTokenTransition,
    txInfoTransition,
    defaultStateTransition,
    successTransition,
  };
};
