import { Button, Container, Text } from 'ui';
import successImg from '../../assets/success.png';
import { FormattedMessage } from 'react-intl';
import { Transaction, TxType } from 'core';

interface TransferSuccessProps {
  onClose(): void;
  handleActivityTabNavigation?(): void;
  tx: Transaction | null;
}

export const TransferSuccess = ({ onClose, tx }: TransferSuccessProps) => {
  const descId =
    tx?.type === TxType.RollupDeposit
      ? 'bridge.deposit.success.desc'
      : 'bridge.withdrawal.success.desc';

  return (
    <Container
      data-testid="transfer-success"
      column
      alignItems="center"
      justifyContent="center"
      className="h-full min-h-[300px]"
    >
      <img src={successImg} alt="success" className="w-20 h-20 mx-auto" />
      <Text type="title-1" id="bridge.transfer.success.title" className="mt-4 mb-2" />
      <Container className="text-center w-3/4" justifyContent="center">
        <Text color="secondary" id={descId} values={{ br: <br /> }} />
      </Container>
      <Button data-testid="close" className="mt-8" onClick={onClose}>
        <FormattedMessage id="bridge.transfer.success.cta" />
      </Button>
    </Container>
  );
};
