import { Container, Text } from 'ui';
import cls from 'classnames';
import { ReactComponent as WalletIcon } from 'icons/wallet.svg';
import { ReactComponent as CloseIcon } from 'icons/close.svg';
import { ClassName, shortenAddress } from 'core';
import { useState } from 'react';
import { isAddress } from 'web3-validator';

interface AddressInputProps extends ClassName {
  address: string;
  onChange: (value: string) => void;
  selectedAccount: string;
}

export const AddressInput = ({
  address,
  className,
  onChange,
  selectedAccount,
}: AddressInputProps) => {
  const [isDisabled, setIsDisabled] = useState(address === selectedAccount);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    onChange(value);
  };

  const isAddressValid = isAddress(address);

  return (
    <Container
      fullWidth
      alignItems="center"
      justifyContent="space-between"
      className={cls(
        'rounded-full border p-2',
        isAddressValid ? 'border-default' : 'border-alert',
        className,
      )}
    >
      <Container alignItems="center" fullWidth>
        <div className="bg-base/[.7] rounded-full p-[6px] mr-2">
          <WalletIcon className="fill-icon-secondary w-[20px] h-auto" />
        </div>
        <input
          onChange={handleChange}
          disabled={isDisabled && isAddressValid}
          value={isDisabled ? shortenAddress(address) : address}
          className={cls(
            'outline-none w-full mr-2 font-body-s bg-transparent',
            isAddressValid ? 'text-default' : 'text-alert',
          )}
        />
      </Container>
      {isDisabled ? (
        <Container onClick={() => setIsDisabled(!isDisabled)} className="mr-2 cursor-pointer">
          <Text
            id="bridge.withdrawal.changeRecipient"
            color="highlight"
            type="body-s"
            className="whitespace-nowrap"
          />
        </Container>
      ) : (
        <Container onClick={() => onChange('')} className="mr-2 cursor-pointer">
          <CloseIcon className="w-5 h-5 fill-icon-secondary " />
        </Container>
      )}
    </Container>
  );
};
