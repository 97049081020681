import * as React from 'react';
import cls from 'classnames';
import { twMerge } from 'tailwind-merge';

import { Children, ClassName, TestId } from '../../types';
import { ForwardedRef, forwardRef } from 'react';
import { Link } from 'react-router-dom';

export type ButtonVariant = 'primary' | 'secondary' | 'accent' | 'outlined' | 'alert' | 'base';
export type ButtonSize = 'l' | 'm' | 's' | 'xs';
export interface ButtonProps
  extends ClassName,
    TestId,
    Children,
    Pick<React.ButtonHTMLAttributes<HTMLButtonElement>, 'type' | 'onClick' | 'onMouseDown'> {
  variant?: ButtonVariant;
  size?: ButtonSize;
  isDisabled?: boolean;
  LeadIcon?: React.FC<React.SVGProps<SVGSVGElement>>;
  TrailIcon?: React.FC<React.SVGProps<SVGSVGElement>>;
  fullWidth?: boolean;
  element?: 'button' | 'link';
  to?: string;
  iconClassName?: string;
}

export const SIZES: Record<ButtonSize, string> = {
  l: 'h-[56px] min-w-[376px] font-body-l px-6 uppercase',
  m: 'h-[48px] min-w-[105px] font-body-m px-6',
  s: 'h-[40px] font-body-m px-4 font-medium normal-case',
  xs: 'h-[20px] font-body-m px-[6px]',
};

export const BUTTON_STYLES: Record<ButtonVariant, Record<string, string>> = {
  primary: {
    default:
      'bg-btn-primary text-inverted hover:outline uppercase ' +
      'hover:!outline-4 hover:!outline-offset-0 hover:!outline-highlight/40',
    disabled: 'bg-btn-disabled text-secondary hover:bg-btn-disabled',
  },
  secondary: {
    default: 'bg-btn-secondary hover:bg-btn-secondary-hover text-secondary hover:text-primary',
    disabled: 'bg-btn-disabled text-secondary hover:bg-btn-disabled',
  },
  accent: {
    default: `px-6 bg-btn-accent text-inverted hover:outline
    hover:!outline-4 hover:!outline-offset-0 hover:!outline-accent/40 uppercase`,
    disabled: 'bg-btn-disabled/5 text-secondary hover:bg-btn-disabled',
  },
  outlined: {
    default: 'border uppercase hover:bg-btn-secondary-hover border-solid border-strong',
    disabled: 'bg-btn-disabled border-none text-btn-disabled hover:bg-btn-disabled',
  },
  alert: {
    default: `px-6 bg-btn-alert text-inverted uppercase hover:outline hover:!outline-4 hover:!outline-offset-0 hover:!outline-alert/40`,
    disabled: 'bg-btn-disabled/5 text-secondary hover:bg-btn-disabled',
  },
  base: {
    default: '',
    disabled: '',
  },
};

const ICON_STYLES = {
  primary: 'fill-icon-inverted',
  secondary: 'fill-icon-secondary',
  accent: 'fill-icon-secondary',
  outlined: 'fill-icon-secondary',
  alert: 'fill-alert',
  base: '',
};

export const Button = forwardRef(
  (
    {
      children,
      variant = 'primary',
      size = 'm',
      isDisabled,
      onClick,
      type = 'button',
      className,
      iconClassName,
      'data-testid': testId,
      LeadIcon,
      TrailIcon,
      fullWidth,
      onMouseDown,
      element,
      to,
    }: ButtonProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const classNames = twMerge(
      cls(
        'box-border flex items-center justify-center rounded-full focus:outline-none min-w-max whitespace-nowrap transition-all',
        BUTTON_STYLES[variant].default,
        variant !== 'base' && SIZES[size],
        { [`cursor-not-allowed hover:!outline-0 ${BUTTON_STYLES[variant].disabled}`]: isDisabled },
        variant === 'secondary' && (LeadIcon || TrailIcon) && 'px-4 h-[36px]',
        (LeadIcon || TrailIcon) && variant === 'secondary' && 'font-medium',
        fullWidth && 'w-full',
        className,
      ),
    );

    const Body = (
      <>
        {LeadIcon && (
          <LeadIcon className={cls('h-auto mr-2', ICON_STYLES[variant], iconClassName)} />
        )}
        {children}
        {TrailIcon && (
          <TrailIcon className={cls('h-auto ml-2', ICON_STYLES[variant], iconClassName)} />
        )}
      </>
    );

    if (element === 'link' && to) {
      return (
        <Link to={to} className={classNames} data-testid={testId}>
          {Body}
        </Link>
      );
    }

    return (
      <button
        ref={ref}
        onMouseDown={onMouseDown}
        className={classNames}
        onClick={onClick}
        disabled={isDisabled}
        type={type}
        data-testid={testId}
      >
        {Body}
      </button>
    );
  },
);
