import { MangataGenericEvent } from 'gasp-sdk';
import { isNil, some } from 'lodash-es';
import {
  ExtrinsicError,
  TransactionErrorEvent,
  TransactionStore,
  ExtrinsicTx,
  TxAsset,
  TxType,
} from '../../../../transaction';
import {
  ReserveSourceType,
  QueryOptional,
  ActivateLiquidityReserveSource,
  InjectedWallet,
} from '../../../../../services';
import { ApiPromise } from '@polkadot/api';
import { DelegateTransactionError } from '../Delegate';
import { BN, BN_ZERO } from '@polkadot/util';
import { createLiqActivationTxsForStaking } from '../../../../pool/activateLiquidity/services/ActivateLiquidityMutationService';
import { ReserveSource, ReserveSourceForTx } from '../../../../balance';

interface DelegateParams {
  collatorAddress: string;
  txAsset: TxAsset;
  candidateDelegationCount: number | null;
  delegatorDelegationCount: number | null;
  reserveSource: ReserveSource;
  activateLiqSources: ReserveSourceForTx<ActivateLiquidityReserveSource>;
  onDone: () => void;
}

const getError = (txEvents: MangataGenericEvent[] | null | undefined) => {
  const failedEvent = txEvents?.find((txEvent) => txEvent.error);

  switch (true) {
    case !!failedEvent:
      return {
        name: failedEvent?.error?.name || ExtrinsicError.Unknown,
        msg: failedEvent?.error?.documentation.join(' '),
      };
    case some(txEvents, { method: DelegateTransactionError.TooLowDelegationCountToDelegate }):
      return {
        name: DelegateTransactionError.TooLowDelegationCountToDelegate,
        msg: null,
      };
  }

  return { name: ExtrinsicError.Unknown };
};

export const submitDelegate =
  (
    api: ApiPromise | null,
    address: string | undefined,
    wallet: QueryOptional<InjectedWallet>,
    transactionStore: TransactionStore,
  ) =>
  async ({
    collatorAddress,
    txAsset,
    candidateDelegationCount,
    delegatorDelegationCount,
    reserveSource,
    activateLiqSources,
    onDone,
  }: DelegateParams) => {
    if (
      !api ||
      !address ||
      !wallet ||
      isNil(candidateDelegationCount) ||
      isNil(delegatorDelegationCount)
    ) {
      return null;
    }

    const extrinsic = createDelegateTx(
      api,
      collatorAddress,
      reserveSource,
      activateLiqSources,
      candidateDelegationCount,
      delegatorDelegationCount,
      txAsset,
    );

    const tx = new ExtrinsicTx(api, transactionStore, wallet, address)
      .create(TxType.Stake)
      .setOptions({ onDone })
      .setMetadata({ tokens: [txAsset] })
      .setTx(extrinsic)
      .build();

    const res = await tx.send();

    if (res?.some((event) => event.method === TransactionErrorEvent.ExtrinsicFailed)) {
      tx.doneWithError(getError(res));
      return false;
    }
  };

const createDelegateTx = (
  api: ApiPromise,
  collatorAddress: string,
  reserveSource: ReserveSource,
  activateLiqSources: ReserveSourceForTx<ActivateLiquidityReserveSource>,
  candidateDelegationCount: number,
  delegatorDelegationCount: number,
  txAsset: TxAsset,
) => {
  const createExtrinsic = (amount: BN) =>
    api.tx.parachainStaking.delegate(
      collatorAddress,
      amount.toString(),
      ReserveSourceType.ActivatedUnstakedReserves,
      candidateDelegationCount,
      delegatorDelegationCount,
    );

  if (
    reserveSource.length === 1 &&
    reserveSource[0][0] === ReserveSourceType.ActivatedUnstakedReserves
  ) {
    return createExtrinsic(reserveSource[0][1]);
  }

  const activationTxs = createLiqActivationTxsForStaking(api, activateLiqSources, txAsset);
  const totalAmount = reserveSource.reduce((acc, [_, amount]) => acc.add(amount), BN_ZERO);

  return api.tx.utility.batchAll([...activationTxs, createExtrinsic(totalAmount)]);
};
