import { useQuery } from '@tanstack/react-query';
import { getRollupApproveFee } from '../services/rollupApproveService';
import { QueryOptional, DEFAULT_QUERY_OPTIONS } from '../../../../../services';
import { isAmountGtZero } from '../../../../../validation';
import { useRolldownContract } from '../../../contract/useRolldownContract';
import { useRollupGasPriceQuery } from '../../../gas/query/useRollupGasPriceQuery';
import { useRollupTokensQuery } from '../../list/query/useRollupTokensQuery';
import { useTokenContract } from '../../contract';

export const useRollupApproveFeeQuery = (
  chainId: QueryOptional<string>,
  userAddress: QueryOptional<string>,
  tokenAddress: QueryOptional<string>,
  amount: QueryOptional<string>,
) => {
  const rolldown = useRolldownContract(chainId);
  const { rollupTokensQuery } = useRollupTokensQuery();
  const { rollupGasPriceQuery } = useRollupGasPriceQuery(chainId);

  const {
    tokenContractMetadataQuery: { data: customToken },
  } = useTokenContract(chainId, tokenAddress, userAddress);

  const tokens = rollupTokensQuery.data;
  const token = tokens?.find((t) => t.address === tokenAddress) ?? customToken?.[0];

  const rollupApproveFeeQuery = useQuery(
    ['rollup-approve-fee', userAddress, amount, rollupGasPriceQuery.data],
    getRollupApproveFee(token, rolldown.address),
    {
      ...DEFAULT_QUERY_OPTIONS,
      enabled:
        !!tokenAddress &&
        !!userAddress &&
        isAmountGtZero(amount) &&
        !!token &&
        !token.isNative &&
        !!rolldown.address,
    },
  );

  return {
    rollupApproveFeeQuery,
  };
};
