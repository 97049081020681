import * as React from 'react';
import cls from 'classnames';
import { Container } from '../../components/Container/Container';
import { useLayoutSize } from '../../hooks';

export enum LayoutSize {
  Narrow = 'narrow',
  Wide = 'wide',
}

export const LAYOUT_SIZE_MAP = {
  [LayoutSize.Narrow]: 'w-[480px] min-w-[480px]',
  [LayoutSize.Wide]: 'w-[840px] min-w-[840px]',
};

export interface ContainerProps {
  children: React.ReactNode;
}

export const AppContainer = ({ children }: ContainerProps) => {
  const { layoutSize } = useLayoutSize();

  return (
    <Container
      fullWidth
      className={cls(
        'h-screen min-w-[480px] px-6 pt-32 relative z-10 overflow-auto no-scrollbar ml-0 xl:-ml-[280px] justify-stretch xl:justify-center',
      )}
    >
      <div className={cls('h-full', LAYOUT_SIZE_MAP[layoutSize])}>{children}</div>
    </Container>
  );
};
