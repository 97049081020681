import { TransactionStore, ExtrinsicTx, TxAsset, TxType } from '../../../transaction';
import {
  QueryOptional,
  ActivateLiquidityReserveSource,
  InjectedWallet,
} from '../../../../services';
import { ApiPromise } from '@polkadot/api';
import { BN } from '@polkadot/util';
import { SubmittableExtrinsic } from '@polkadot/api/types';
import { Asset } from '../../../token';
import { ReserveSourceForTx } from '../../../balance';
import { BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS, fromBN } from 'gasp-sdk';

interface ActivateLiquidityParams {
  id: string;
  amount: BN;
  symbol: string;
  balanceSources: ReserveSourceForTx<ActivateLiquidityReserveSource>;
}

const createActivateLiquidityTx = (
  api: ApiPromise,
  id: string,
  sources: ReserveSourceForTx<ActivateLiquidityReserveSource>,
) => {
  if (!sources.hasAvailable) {
    return;
  }

  if (sources.isBatch) {
    const txs = sources.available.map(([type, amount]) => {
      return api.tx.proofOfStake.activateLiquidity(id, amount.toString(), type);
    });

    return api.tx.utility.batchAll(txs);
  }

  return api.tx.proofOfStake.activateLiquidity(
    id,
    sources.totalAvailableBalance,
    sources.firstAvailableSource,
  );
};

export const activateLiquidity =
  (
    api: ApiPromise | null,
    address: string | undefined,
    wallet: QueryOptional<InjectedWallet>,
    transactionStore: TransactionStore,
  ) =>
  async ({ id, balanceSources, symbol }: ActivateLiquidityParams) => {
    if (!api || !address || !wallet || !balanceSources.hasAvailable) {
      return null;
    }

    const extrinsic = createActivateLiquidityTx(api, id, balanceSources);
    if (!extrinsic) {
      return null;
    }

    const amount = fromBN(
      balanceSources.totalAvailableBalance,
      BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS,
    );

    return new ExtrinsicTx(api, transactionStore, wallet, address)
      .create(TxType.ActivateLP)
      .setMetadata({ amount, symbol })
      .setTx(extrinsic)
      .build()
      .send();
  };

export const createLiqActivationTxsForStaking = (
  api: ApiPromise,
  sources: ReserveSourceForTx<ActivateLiquidityReserveSource>,
  txAsset: Asset | TxAsset,
) =>
  sources.available
    .map(([type, amount]) => {
      return api.tx.proofOfStake.activateLiquidityForNativeRewards(
        txAsset.id,
        amount.toString(),
        type,
      );
    })
    .filter((tx): tx is SubmittableExtrinsic<'promise'> => !!tx);
