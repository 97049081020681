import { Codec } from '@polkadot/types/types';
import { QueryFunctionContext } from '@tanstack/react-query';
import { ApiPromise } from '@polkadot/api';
import { InjectedWallet, QueryOptional } from '../../../../services';
import { TransactionStore, ExtrinsicTx, TxType } from '../../../transaction';
import { BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS, BN_ZERO, fromBN, PoolWithShare } from 'gasp-sdk';
import { BN } from '@polkadot/util';
import { UseQueryResult } from '@tanstack/react-query';
import { SubmittableExtrinsic } from '@polkadot/api/types';

interface ClaimAllPoolRewardsParams {
  lpId: string;
}

export const claimAllPoolRewards =
  (
    api: ApiPromise | null,
    address: string | undefined,
    wallet: QueryOptional<InjectedWallet>,
    transactionStore: TransactionStore,
    thirdPartyRewardsAmount: QueryOptional<Map<string, Map<string, Codec>>>,
    rewardsByLpId: Record<string, UseQueryResult<QueryOptional<BN>>>,
  ) =>
  async ({ lpId }: ClaimAllPoolRewardsParams) => {
    if (!api || !address || !wallet || !thirdPartyRewardsAmount) {
      return null;
    }

    const extrinsics = getClaimPoolRewardsTransactions(
      api,
      thirdPartyRewardsAmount,
      rewardsByLpId,
      lpId,
    );
    const tx = extrinsics.length === 1 ? extrinsics[0] : api.tx.utility.batch(extrinsics);

    return new ExtrinsicTx(api, transactionStore, wallet, address)
      .create(TxType.ClaimPoolRewards)
      .setTx(tx)
      .build()
      .send();
  };

const getClaimPoolRewardsTransactions = (
  api: ApiPromise,
  thirdPartyRewardsAmount: Map<string, Map<string, Codec>>,
  rewardsByLpId: Record<string, UseQueryResult<QueryOptional<BN>>>,
  lpId: string,
) => {
  const txs = [];

  const nativeRewardsAmount = rewardsByLpId[lpId].data;
  if (nativeRewardsAmount?.gt(BN_ZERO)) {
    txs.push(api.tx.proofOfStake.claimNativeRewards(lpId));
  }

  const pool3rdPartyRewards = thirdPartyRewardsAmount.get(lpId);
  const claimable3rdPartyRewards = Array.from(pool3rdPartyRewards?.entries() ?? []).filter(
    ([, amount]) => new BN(amount.toString()).gt(BN_ZERO),
  );

  claimable3rdPartyRewards.forEach(([id]) => {
    txs.push(api.tx.proofOfStake.claim3rdpartyRewards(lpId, id));
  });

  return txs;
};

type ClaimAllPoolRewardsQueryKey = Readonly<
  [queryKey: string, address: string | undefined, lpId: string | undefined]
>;

export const getClaimAllPoolRewardsFee =
  (
    api: ApiPromise | null,
    thirdPartyRewardsAmount: QueryOptional<Map<string, Map<string, Codec>>>,
    rewardsByLpId: Record<string, UseQueryResult<QueryOptional<BN>>>,
  ) =>
  async ({ queryKey: [, address, lpId] }: QueryFunctionContext<ClaimAllPoolRewardsQueryKey>) => {
    if (!api || !address || !thirdPartyRewardsAmount || !lpId || !rewardsByLpId) {
      return null;
    }

    const txs = getClaimPoolRewardsTransactions(api, thirdPartyRewardsAmount, rewardsByLpId, lpId);
    const feePromise = txs.map((tx) => tx.paymentInfo(address));

    const fees = await Promise.all(feePromise);

    const totalFee = fees.reduce((acc, fee) => acc.add(fee.partialFee), BN_ZERO);

    return fromBN(totalFee, BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS);
  };

export const claimAllRewards =
  (
    api: ApiPromise | null,
    address: string | undefined,
    wallet: QueryOptional<InjectedWallet>,
    transactionStore: TransactionStore,
    thirdPartyRewardsAmount: QueryOptional<Map<string, Map<string, Codec>>>,
    rewardsByLpId: Record<string, UseQueryResult<QueryOptional<BN>>>,
    pools: QueryOptional<PoolWithShare[]>,
  ) =>
  async () => {
    if (!api || !address || !wallet || !thirdPartyRewardsAmount || !pools) {
      return null;
    }

    const extrinsics = pools.reduce((acc, pool) => {
      const poolTxs = getClaimPoolRewardsTransactions(
        api,
        thirdPartyRewardsAmount,
        rewardsByLpId,
        pool.liquidityTokenId,
      );
      return acc.concat(poolTxs);
    }, [] as SubmittableExtrinsic<'promise'>[]);

    const tx = extrinsics.length === 1 ? extrinsics[0] : api.tx.utility.batch(extrinsics);
    return new ExtrinsicTx(api, transactionStore, wallet, address)
      .create(TxType.ClaimAll)
      .setTx(tx)
      .build()
      .send();
  };

export const getClaimAllRewardsFee =
  (
    api: ApiPromise | null,
    thirdPartyRewardsAmount: QueryOptional<Map<string, Map<string, Codec>>>,
    rewardsByLpId: Record<string, UseQueryResult<QueryOptional<BN>>>,
    pools: QueryOptional<PoolWithShare[]>,
    address: string | undefined,
  ) =>
  async () => {
    if (!api || !pools || !thirdPartyRewardsAmount || !rewardsByLpId || !address) {
      return null;
    }

    const txs = pools.reduce((acc, pool) => {
      const poolTxs = getClaimPoolRewardsTransactions(
        api,
        thirdPartyRewardsAmount,
        rewardsByLpId,
        pool.liquidityTokenId,
      );
      return [...acc, ...poolTxs];
    }, [] as SubmittableExtrinsic<'promise'>[]);

    const feePromise = txs.map((tx) => tx.paymentInfo(address));

    const fees = await Promise.all(feePromise);

    const totalFee = fees.reduce((acc, fee) => acc.add(fee.partialFee), BN_ZERO);

    return fromBN(totalFee, BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS);
  };
