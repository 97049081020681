import { Decimal } from 'decimal.js';
import { fromBN } from 'gasp-sdk';
import { PoolWithRatio } from '../../../pool';
import { useAllPoolsQuery } from '../../../pool/all/query/useAllPoolsQuery';
import { usePriceDiscoveryQueries } from '../../../tokens';
import { UseQueryResult } from '@tanstack/react-query';

export const usePoolTvlQueries = () => {
  const {
    allPoolsQuery: { data: pools },
  } = useAllPoolsQuery();

  const symbols = pools?.baseList.reduce((acc, pool) => {
    return [...acc, pool.firstAsset.symbol, pool.secondAsset.symbol];
  }, [] as string[]);

  const { priceDiscoveryQueriesBySymbol } = usePriceDiscoveryQueries(symbols ?? []);

  const tvlQueriesMap = new Map(
    pools?.baseList.map((pool) => {
      return [pool.liquidityTokenId, calculateTvl(pool, priceDiscoveryQueriesBySymbol)];
    }),
  );

  return tvlQueriesMap;
};

export const usePoolTvlQuery = (poolId: string | undefined) => {
  const {
    allPoolsQuery: { data },
  } = useAllPoolsQuery();

  const pool = (poolId && data?.byId[poolId]) || null;
  const poolSymbols = pool ? [pool.firstAsset.symbol, pool.secondAsset.symbol] : null;

  const { priceDiscoveryQueriesBySymbol } = usePriceDiscoveryQueries(poolSymbols);

  if (!pool) {
    return null;
  }

  return calculateTvl(pool, priceDiscoveryQueriesBySymbol);
};

function calculateTvl(
  pool: PoolWithRatio,
  priceDiscoveryQueriesBySymbol: Record<string, UseQueryResult<Record<string, string> | null>>,
) {
  const firstTokenPrice = priceDiscoveryQueriesBySymbol[pool.firstAsset.symbol]?.data?.['usd'];

  if (firstTokenPrice && firstTokenPrice !== '0' && pool.firstTokenAmount.gtn(0)) {
    return new Decimal(fromBN(pool.firstTokenAmount, pool.firstAsset.decimals))
      .mul(2)
      .mul(firstTokenPrice)
      .toFixed(1);
  } else {
    const secondTokenPrice = priceDiscoveryQueriesBySymbol[pool.secondAsset.symbol]?.data?.['usd'];

    if (secondTokenPrice && secondTokenPrice !== '0' && pool.secondTokenAmount.gtn(0)) {
      return new Decimal(fromBN(pool.secondTokenAmount, pool.secondAsset.decimals))
        .mul(2)
        .mul(secondTokenPrice)
        .toFixed(1);
    }
  }

  return '0';
}
