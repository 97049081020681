import { useMemo, useState } from 'react';
import { isAddress } from 'web3-validator';
import { useDepositStore } from '../../store/useDepositStore';

export const useCustomTokenDepositState = () => {
  const { chain } = useDepositStore();

  const [selectedChain, setSelectedChain] = useState<string>(chain?.id ?? '');
  const [selectedTokenContract, setSelectedTokenContract] = useState<string>('');

  const isTokenContractValid = useMemo(() => {
    return isAddress(selectedTokenContract);
  }, [selectedTokenContract]);

  return {
    selectedChain,
    selectedTokenContract,
    setSelectedChain,
    setSelectedTokenContract,
    isTokenContractValid,
  };
};
