import { AccountInfo } from './components/AccountInfo/AccountInfo';
import { ConnectWallet } from './components/ConnectWallet/ConnectWallet';
import { Button, Container, Text } from 'ui';
import { TestId, WalletAccount, useModalStore } from 'core';
import { useWalletUIStore } from '../../store/useWalletUIStore';
import { Deposit, Withdrawal } from '../../../Bridge';
import { ReactComponent as WithdrawIcon } from 'icons/send.svg';
import { ReactComponent as DepositIcon } from 'icons/deposit.svg';

export interface WalletHeaderProps extends TestId {
  animating: boolean;
  selectedWallet: string | null;
  selectedAccount: WalletAccount | null;
}

export function WalletHeader({
  animating,
  selectedWallet,
  selectedAccount,
  'data-testid': testId,
}: WalletHeaderProps) {
  const { isWalletOpen, setIsWalletOpen } = useWalletUIStore();
  const { openWithdrawal, openDeposit, isDepositOpen, isWithdrawalOpen } = useModalStore();

  const showAccountInfo = selectedWallet && selectedAccount;
  const showConnect = !animating && !isWalletOpen && !selectedAccount;

  const toggleWalletVisibility = () => setIsWalletOpen(!isWalletOpen);

  return (
    <>
      {isDepositOpen && <Deposit />}
      {isWithdrawalOpen && <Withdrawal />}
      {showConnect && <ConnectWallet data-testid={testId} onToggle={toggleWalletVisibility} />}
      {showAccountInfo && (
        <Container column fullWidth data-testid={testId}>
          <AccountInfo
            account={selectedAccount}
            open={isWalletOpen}
            wallet={selectedWallet}
            onToggle={toggleWalletVisibility}
            data-testid={testId}
          />

          {isWalletOpen && (
            <Container fullWidth className="p-3">
              <Button size="s" onClick={openDeposit} fullWidth variant="primary" className="mr-3">
                <DepositIcon className="mr-2 w-5 h-auto stroke-icon-inverted" />
                <Text type="btn-m" color="inverted" id="wallet.deposit" />
              </Button>
              <Button size="s" onClick={openWithdrawal} variant="outlined">
                <WithdrawIcon className="mr-2 w-5 h-auto fill-icon-secondary" />
                <Text type="btn-m" color="secondary" id="wallet.withdraw" />
              </Button>
            </Container>
          )}
        </Container>
      )}
    </>
  );
}
