import { useEffect, useRef } from 'react';
import { Asset, useModalStore } from 'core';
import { Container, Modal } from 'ui';
import { RollupWithdrawalForm } from './RollupWithdrawalForm';
import { useRollupWithdrawalTransitions } from './useRollupWithdrawalTransitions';
import { animated } from '@react-spring/web';
import { WithdrawalUIState, useWithdrawalStore } from '../store/useWithdrawalStore';
import { useRollupWithdrawalState } from './useRollupWithdrawalState';
import { TokenSelect } from '../components/TokenSelect/TokenSelect';
import { WithdrawalTxInfo } from '../components/WithdrawalTxInfo/WithdrawalTxInfo';
import { TransferSuccess } from '../components/TransferSuccess/TransferSuccess';

export function RollupWithdrawal() {
  const defaultContentRef = useRef<HTMLDivElement>(null);
  const txInfoRef = useRef<HTMLDivElement>(null);
  const successRef = useRef<HTMLDivElement>(null);
  const selectTokenRef = useRef<HTMLDivElement>(null);

  const refMap: Record<WithdrawalUIState, React.RefObject<HTMLDivElement>> = {
    [WithdrawalUIState.Default]: defaultContentRef,
    [WithdrawalUIState.Withdrawing]: txInfoRef,
    [WithdrawalUIState.Success]: successRef,
    [WithdrawalUIState.SelectToken]: selectTokenRef,
  };

  const {
    submitWithdrawal,
    withdrawalAccounts,
    withdrawalAssets,
    withdrawalBalances,
    isWithdrawalReady,
    isWithdrawing,
    tx,
    handleClose,
  } = useRollupWithdrawalState();
  const { dispose, setUIState, setAsset, destinationAddress, uiState } = useWithdrawalStore();
  const { isWithdrawalOpen, closeWithdrawal } = useModalStore();

  useEffect(() => {
    return () => {
      dispose();
    };
  }, [dispose]);

  const handleTokenSelectClick = () => {
    setUIState(WithdrawalUIState.SelectToken);
  };

  const handleTokenSelect = (asset: Asset) => {
    setAsset(asset);
    setUIState(WithdrawalUIState.Default);
  };

  const {
    containerSpring,
    defaultStateTransition,
    txInfoTransition,
    successTransition,
    selectTokenTransition,
  } = useRollupWithdrawalTransitions(refMap[uiState], tx);

  return (
    <Modal
      isCloseIconVisible={false}
      isClosableOnOverlayClick={false}
      isOpen={isWithdrawalOpen}
      onClose={closeWithdrawal}
      className={'w-[480px] rounded-xl py-6 overflow-hidden'}
      overlayClassName="bg-base/80"
      data-testid="withdrawal-modal"
    >
      <animated.div style={containerSpring} className="relative w-full">
        {successTransition((style, visible) =>
          visible ? (
            <animated.div style={style} className="w-full absolute">
              <Container column ref={successRef} fullWidth>
                <TransferSuccess
                  tx={tx}
                  onClose={handleClose}
                  handleActivityTabNavigation={handleClose}
                />
              </Container>
            </animated.div>
          ) : null,
        )}
        {txInfoTransition((style, visible) =>
          visible && destinationAddress && tx ? (
            <animated.div style={style} className="w-full absolute">
              <Container column ref={txInfoRef} fullWidth>
                <WithdrawalTxInfo to={destinationAddress} tx={tx} />
              </Container>
            </animated.div>
          ) : null,
        )}
        {selectTokenTransition((style, visible) =>
          visible ? (
            <animated.div style={style} className="w-full absolute">
              <Container column ref={selectTokenRef} fullWidth>
                {withdrawalAssets && (
                  <TokenSelect
                    onBack={() => setUIState(WithdrawalUIState.Default)}
                    onClose={closeWithdrawal}
                    onTokenSelect={handleTokenSelect}
                    tokens={withdrawalAssets}
                    balances={withdrawalBalances}
                    titleId="bridge.withdrawal.selectToken.title"
                  />
                )}
              </Container>
            </animated.div>
          ) : null,
        )}
        {defaultStateTransition((style, visible) =>
          visible ? (
            <animated.div style={style} className="w-full absolute">
              <Container column ref={defaultContentRef} fullWidth>
                <RollupWithdrawalForm
                  onTokenSelectClick={handleTokenSelectClick}
                  submitWithdrawal={submitWithdrawal}
                  accounts={withdrawalAccounts}
                  assets={withdrawalAssets}
                  balances={withdrawalBalances}
                  isWithdrawalReady={isWithdrawalReady}
                  isWithdrawing={isWithdrawing}
                />
              </Container>
            </animated.div>
          ) : null,
        )}
      </animated.div>
    </Modal>
  );
}
