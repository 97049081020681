import { Decimal } from 'decimal.js';
import { EnvConfig } from '../../../envConfig';
import { TxAsset } from '../../transaction';
import { Asset } from '../../token/Token';

export interface FeeAssetWithScale extends Asset {
  scaleFactor: string;
}

export type AdaptiveTxFee = {
  current: TxAsset | null;
  allSufficient: TxAsset[];
  all: TxAsset[];
};

const getFeeTxAsset = (
  feeAsset: FeeAssetWithScale | null,
  mgxAsset: FeeAssetWithScale | null,
  mgxFee: string,
) => {
  if (feeAsset && mgxAsset) {
    const feeForToken = new Decimal(`${mgxFee}e${mgxAsset.decimals}`).div(feeAsset.scaleFactor);
    const amount = feeForToken.div(`1e${feeAsset.decimals}`).toFixed(feeAsset.decimals);

    return {
      ...feeAsset,
      amount,
    };
  }
};

export type GetAdaptiveFee = (amount: string | null) => AdaptiveTxFee | null;

export const getAdaptiveTxFee = (
  mgxFee: string,
  feeAssets: (FeeAssetWithScale | null)[],
  getFreeBalance: (asset: Asset | null | undefined) => string | null,
): AdaptiveTxFee => {
  const mgxAsset = feeAssets.find((asset) => asset?.id === EnvConfig.TOKEN_ID);

  const all = feeAssets
    .map((feeAsset) => getFeeTxAsset(feeAsset, mgxAsset || null, mgxFee))
    .filter(Boolean) as TxAsset[];
  const sufficient = all.filter((feeAsset) => {
    const freeBalance = getFreeBalance(feeAsset);

    return freeBalance && new Decimal(freeBalance).gte(feeAsset.amount);
  });

  const defaultAsset = mgxAsset ? { ...mgxAsset, amount: mgxFee } : null;

  return {
    current: sufficient[0] || defaultAsset,
    allSufficient: sufficient,
    all,
  };
};
