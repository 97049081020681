import cls from 'classnames';
import * as React from 'react';

import { ReactComponent as LinkIcon } from '../../../assets/icons/link-ext.svg';
import { Children, ClassName, TestId } from '../../types';

type LinkButtonVariant = 'accent' | 'secondary' | 'inverted';

export interface LinkButtonProps
  extends Children,
    ClassName,
    TestId,
    Pick<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'target' | 'onClick' | 'href'> {
  variant?: LinkButtonVariant;
  iconClassName?: string;
  isExternal?: boolean;
}

const DEFAULT_STYLES = {
  accent: {
    btn: 'text-highlight hover:text-highlight-hover',
    icon: 'fill-icon-accent group-hover/link:fill-icon-accent-hover',
  },
  secondary: {
    btn: 'text-secondary hover:text-highlight-hover',
    icon: 'fill-icon-secondary group-hover/link:fill-icon-accent-hover',
  },
  inverted: {
    btn: 'text-neutral hover:text-secondary',
    icon: 'fill-icon-inverted group-hover/link:fill-icon-secondary',
  },
};

export function LinkButton({
  className,
  iconClassName,
  onClick,
  'data-testid': testId,
  children,
  variant = 'accent',
  href,
  target,
  isExternal = true,
}: LinkButtonProps) {
  return (
    <a
      className={cls('group/link cursor-pointer flex', DEFAULT_STYLES[variant].btn, className)}
      onClick={onClick}
      data-testid={testId}
      href={href}
      target={target}
    >
      {children}
      {isExternal && (
        <LinkIcon
          className={(cls('ml-[2px] h-full w-[22px]', DEFAULT_STYLES[variant].icon), iconClassName)}
        />
      )}
    </a>
  );
}
