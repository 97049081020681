import Big from 'big.js';
import { fromBN } from 'gasp-sdk';
import { UseQueryResult } from '@tanstack/react-query';
import { PoolWithRatio } from '../../../Pool';
import { EnvConfig } from '../../../../../envConfig';
import { PromotedPoolATvlResult } from '../../../../../services';

export interface PromotedPoolAprProps {
  prices: Record<string, UseQueryResult<Record<string, string> | null>>;
  pool: PoolWithRatio | undefined;
  poolATvl: PromotedPoolATvlResult | null | undefined;
  annualRewards: string | undefined;
  mgxSymbol: string | undefined;
}

const MGX_TOKEN_ID = EnvConfig.TOKEN_ID;
const FIXED_PLACES = 0;

export const getPromotedPoolApr = ({
  pool,
  poolATvl,
  prices,
  annualRewards,
  mgxSymbol,
}: PromotedPoolAprProps) => {
  if (!pool || !poolATvl || !annualRewards || !mgxSymbol) {
    return null;
  }

  const annualMgxRewards_big = Big(annualRewards);
  const firstAssetAmount = poolATvl[0];
  const secondAssetAmount = poolATvl[1];

  if (pool.firstTokenId === MGX_TOKEN_ID || pool.secondTokenId === MGX_TOKEN_ID) {
    const isFirstMGX = pool.firstTokenId === MGX_TOKEN_ID;
    const burnAmount = isFirstMGX ? firstAssetAmount : secondAssetAmount;

    if (!burnAmount.eqn(0)) {
      return annualMgxRewards_big
        .div(
          Big(
            fromBN(burnAmount, isFirstMGX ? pool.firstAsset.decimals : pool.secondAsset.decimals),
          ).mul(2),
        )
        .mul(100)
        .toFixed(FIXED_PLACES);
    }
  }

  const mgxPrice = prices[mgxSymbol]?.data?.['usd'];

  if (mgxPrice) {
    const rewardsInUSD = annualMgxRewards_big.mul(mgxPrice);
    const firstTokenPrice = prices[pool.firstAsset.symbol]?.data?.['usd'];

    if (firstTokenPrice && firstTokenPrice !== '0') {
      if (!firstAssetAmount.eqn(0)) {
        const poolActivatedTvl = Big(fromBN(firstAssetAmount, pool.firstAsset.decimals))
          .mul(2)
          .mul(firstTokenPrice);

        if (poolActivatedTvl.gt(0)) {
          return rewardsInUSD.div(poolActivatedTvl).mul(100).toFixed(FIXED_PLACES);
        }
      }
    } else {
      const secondTokenPrice = prices[pool.secondAsset.symbol]?.data?.['usd'];
      if (secondTokenPrice && secondTokenPrice !== '0') {
        if (!secondAssetAmount.eqn(0)) {
          const poolActivatedTvl = Big(fromBN(secondAssetAmount, pool.secondAsset.decimals))
            .mul(2)
            .mul(secondTokenPrice);

          if (poolActivatedTvl.gt(0)) {
            return rewardsInUSD.div(poolActivatedTvl).mul(100).toFixed(FIXED_PLACES);
          }
        }
      }
    }
  }

  return null;
};
