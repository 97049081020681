import Web3 from 'web3';
import { erc20Abi } from '../../../ContractAbis.json';
import { QueryOptional } from '../../../../../services';
import { isAddress } from 'web3-validator';

export const getTokenContractMetadata =
  (web3: Web3 | null, userAddress: QueryOptional<string>, address: QueryOptional<string>) =>
  async () => {
    if (!web3 || !address || !isAddress(address)) {
      return null;
    }

    const contract = new web3.eth.Contract(erc20Abi, address);

    const name = await contract?.methods.name().call();
    const symbol = await contract?.methods.symbol().call();
    const decimals = await contract?.methods.decimals().call();
    if (!userAddress) {
      return { name, symbol, decimals, contract, address };
    }

    const balance = await contract?.methods.balanceOf(userAddress).call();
    return { name, symbol, decimals, contract, address, balance };
  };
