import { useQuery } from '@tanstack/react-query';
import { getExtensionsCount } from '../../../services';
import { fetchWallets, getSelectedWallet } from '../services/walletService';
import { useSDK as useMetamask } from '@metamask/sdk-react';

const queryOptions = {
  refetchOnWindowFocus: false,
  refetchOnMount: false,
  retry: 0,
};

export function useWalletQuery(selectedWallet: string | null) {
  const { sdk: metamaskSdk, provider } = useMetamask();

  const listWalletsQuery = useQuery(
    ['wallets-query', getExtensionsCount(), !!provider],
    fetchWallets(),
    {
      ...queryOptions,
    },
  );

  const selectedWalletQuery = useQuery(
    ['selected-wallet', selectedWallet, !!provider, !!metamaskSdk],
    getSelectedWallet(selectedWallet, listWalletsQuery.data, metamaskSdk, provider),
    {
      ...queryOptions,
      enabled: !!listWalletsQuery.data && selectedWallet !== null,
    },
  );

  return {
    listWalletsQuery,
    selectedWalletQuery,
  };
}
