import { omit } from 'lodash-es';
import { EnvConfig } from '../../../../../envConfig';
import { ChainType, ExtrinsicPath, StashChannel, XcmVersion } from '../../../../stash';
import {
  XcmDestination,
  XcmDestinationBase,
  XcmDestinationInteriorX2,
  XcmParents,
} from '../../types';

export function buildDestination(
  address: string,
  extrinsicPath: ExtrinsicPath,
  channel: StashChannel,
): XcmDestination {
  switch (extrinsicPath) {
    case ExtrinsicPath.TransferMultiasset:
      return buildTransferMultiassetDestinationPayload(address, channel.xcmVersion, extrinsicPath);

    case ExtrinsicPath.PolkadotXcmLimitedTransfer:
    case ExtrinsicPath.XcmPalletLimitedTransfer:
      return {
        V3: {
          parents: getParentsValue(channel.chainType),
          interior: {
            X1: {
              Parachain: EnvConfig.MANGATA_PARACHAIN_ID,
            },
          },
        },
      };
  }
}

function getParentsValue(chainType: ChainType): XcmParents {
  switch (chainType) {
    case ChainType.Parachain:
      return 1;
    case ChainType.RelayChain:
      return 0;
  }
}

function buildTransferMultiassetDestinationPayload(
  address: string,
  version: XcmVersion,
  extrinsicPath: ExtrinsicPath,
): XcmDestination {
  const destinationBase: XcmDestinationBase<XcmDestinationInteriorX2> = {
    parents: 1,
    interior: {
      X2: [
        {
          Parachain: EnvConfig.MANGATA_PARACHAIN_ID,
        },
        {
          AccountId32: {
            network: 'Any',
            id: address,
          },
        },
      ],
    },
  };

  switch (version) {
    case XcmVersion.V2:
      return { V2: destinationBase };
    case XcmVersion.V3:
      switch (extrinsicPath) {
        case ExtrinsicPath.XcmPalletLimitedTransfer:
        case ExtrinsicPath.PolkadotXcmLimitedTransfer:
          return { V3: destinationBase };
        case ExtrinsicPath.TransferMultiasset:
          return { V3: omit(destinationBase, 'interior.X2[1].AccountId32.network') };
        default:
          throw new Error(
            `Unsupported extrinsic path: ${extrinsicPath} for XCM version: ${version}`,
          );
      }
    default:
      throw new Error(`Unsupported XCM version: ${version}`);
  }
}
