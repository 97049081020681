import { BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS, fromBN } from 'gasp-sdk';
import { QueryFunctionContext } from '@tanstack/react-query';
import { ApiPromise } from '@polkadot/api';
import { QueryOptional } from '../../../../services';

type DeactivateLiquidityFor3rdPartyRewardsFeeParams = Readonly<
  [
    id: QueryOptional<string>,
    liquidityTokenId: QueryOptional<string>,
    address: QueryOptional<string>,
    rewardTokenId: QueryOptional<string>,
  ]
>;

export const getDeactivateLiquidityFor3rdPartyRewardsFee =
  (api: ApiPromise | null) =>
  async ({
    queryKey: [, liquidityTokenId, address, rewardTokenId],
  }: QueryFunctionContext<DeactivateLiquidityFor3rdPartyRewardsFeeParams>) => {
    if (!api || !address || !liquidityTokenId || !rewardTokenId) {
      return null;
    }

    const feeInfo = await api.tx.proofOfStake
      .deactivateLiquidityFor3rdpartyRewards(liquidityTokenId, '0', rewardTokenId)
      .paymentInfo(address);

    return fromBN(feeInfo.partialFee, BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS);
  };
